.admin {
  align-items: center;
  background-color: white;
  border-radius: 22px;
  grid-column-gap: 1rem;
  grid-template-areas: 'content buttons' 'link link';
  max-width: 80vw;
  padding: 1.1rem;
  * {
    text-align: center;
  }
  button {
    margin: 5px !important;
  }
}
.Home {
  @include grid(auto 100px, 2fr 1fr);
  align-items: center;
  background-color: white;
  border-radius: 22px;
  grid-column-gap: 1rem;
  grid-template-areas: 'content buttons' 'link link';
  max-width: 80vw;
  padding: 1.1rem;

  &__content {
    grid-area: content;
  }

  &__buttons {
    grid-area: buttons;
    text-align: center;
  }

  &__title {
    color: #002c79;
    font-size: 2.5rem;
    line-height: 39px;
    small {
      font-weight: 200;
      display: inline-block;
      padding:  0 0 20px 0;
    }
  }

  &__button {
    background-color: #002c79;
    border-radius: 0.6rem;
    color: white;
    display: flex;
    margin: 1rem auto;
    max-width: 300px;
    height: 47px;
    justify-content: center;
    align-items: center;
    font-weight: $font--bold;
    text-align: center;
    text-decoration: none;
    transition: 300ms;
    grid-area: buttons;
    &:hover {
      background-color: black;
    }
  }
  &__link {
    grid-area: link;
    text-align: center;
    border-top: 0.5px solid lightgray;
    color: gray;
    padding: 1rem 0;
    align-self: end;
  }
}
@media only screen and (max-width: 600px) {
  .Home {
    @include grid(repeat(3, auto), 1fr);
    grid-template-areas: 'content' 'buttons' 'link';
    justify-content: center;
  }
}
