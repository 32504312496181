@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:300,400,700&display=swap);
h1,
h2 {
  margin: 0; }

p {
  margin: 0; }
  p small {
    font-size: 0.75em; }

.printable {
  padding: 30px; }

:root {
  font-size: 20px; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  min-height: 100vh;
  margin: 0;
  font-size: 20px; }

h1,
h2,
h3,
h4,
h5,
p,
label,
input,
textarea,
button,
a {
  font-family: "Montserrat", sans-serif !important; }

.App {
  display: grid;
  grid-template: 100px 1fr/1fr;
  min-height: 100vh;
  background-color: #cacaca; }

.Content__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px); }

form {
  background-color: white;
  border-radius: 5px; }

.Registration__form {
  padding: 20px; }
  .Registration__form .Registration__input {
    width: 33%; }

.ui.button {
  margin: 5px; }
  .ui.button.primary {
    background-color: #1f293a; }
    .ui.button.primary:hover {
      background-color: #354e7a; }

@media only screen and (max-width: 700px) {
  .Registration__input,
  input,
  .input {
    display: block;
    width: 100% !important; } }

@media only screen and (max-width: 600px) {
  .input {
    padding: 5px !important; } }

.admin {
  align-items: center;
  background-color: white;
  border-radius: 22px;
  grid-column-gap: 1rem;
  grid-template-areas: 'content buttons' 'link link';
  max-width: 80vw;
  padding: 1.1rem; }
  .admin * {
    text-align: center; }
  .admin button {
    margin: 5px !important; }

.Home {
  display: grid;
  grid-template: auto 100px/2fr 1fr;
  align-items: center;
  background-color: white;
  border-radius: 22px;
  grid-column-gap: 1rem;
  grid-template-areas: 'content buttons' 'link link';
  max-width: 80vw;
  padding: 1.1rem; }
  .Home__content {
    grid-area: content; }
  .Home__buttons {
    grid-area: buttons;
    text-align: center; }
  .Home__title {
    color: #002c79;
    font-size: 2.5rem;
    line-height: 39px; }
    .Home__title small {
      font-weight: 200;
      display: inline-block;
      padding: 0 0 20px 0; }
  .Home__button {
    background-color: #002c79;
    border-radius: 0.6rem;
    color: white;
    display: flex;
    margin: 1rem auto;
    max-width: 300px;
    height: 47px;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    transition: 300ms;
    grid-area: buttons; }
    .Home__button:hover {
      background-color: black; }
  .Home__link {
    grid-area: link;
    text-align: center;
    border-top: 0.5px solid lightgray;
    color: gray;
    padding: 1rem 0;
    align-self: end; }

@media only screen and (max-width: 600px) {
  .Home {
    display: grid;
    grid-template: repeat(3, auto)/1fr;
    grid-template-areas: 'content' 'buttons' 'link';
    justify-content: center; } }

.AdminTable {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
  min-width: 70%; }
  .AdminTable.__content {
    display: block; }
  .AdminTable table {
    border-collapse: collapse;
    width: 100%; }
  .AdminTable .leaderNameFilter input {
    width: -webkit-fill-available; }
  .AdminTable td,
  .AdminTable th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 0 6px; }
  .AdminTable tr:nth-child(even) {
    background-color: #dddddd; }
  .AdminTable .options .search-query p.title {
    margin: 10px 0 0 0; }
  .AdminTable .options .search-query input {
    margin: 0 0 20px 0; }

.Button {
  background-color: #2596c2;
  border-radius: 0.6rem;
  border: 0;
  color: #fff;
  outline: none;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: inherit;
  cursor: pointer;
  transition: 300ms;
  width: 100%;
  max-width: 15rem;
  margin: 15px 0 15px 0;
  text-align: center; }
  .Button:hover {
    background-color: #222; }
  .Button:disabled {
    background-color: #d3d3d3;
    color: #a3a3a3; }
  .Button--tag {
    max-width: 50%; }

.CheckIn {
  width: 70%;
  background-color: #fff;
  border-radius: 22px;
  height: auto;
  max-width: 80vw;
  padding: 1.1rem; }
  .CheckIn .info {
    margin: 20px 0; }
    .CheckIn .info span {
      margin: 0 10px; }
  @media only screen and (max-width: 700px) {
    .CheckIn {
      width: 95%; } }

.GuestIdForm {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px; }
  .GuestIdForm form {
    max-width: 300px; }
  .GuestIdForm .Registration__input {
    padding: 0; }

.header#nav {
  background-color: #1f293a;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 15px 60px;
  align-items: center;
  display: flex; }
  .header#nav img {
    max-width: 100%;
    display: block;
    height: 50px; }
  .header#nav a {
    display: inline-block;
    width: 50%; }
    .header#nav a.logout {
      text-align: end;
      padding: 0 20px 0 0; }

.Registration {
  background-color: #fff;
  border-radius: 22px;
  height: auto;
  max-width: 80vw;
  padding: 1.1rem; }
  .Registration__title {
    color: #2596c2;
    font-size: 2.5rem; }
  .Registration__input {
    padding: 0.3rem 0.5rem 0.3rem 1rem;
    font-size: 20px;
    border-radius: 22px;
    border: none;
    margin: 0.1rem auto; }
    .Registration__input::-webkit-input-placeholder {
      color: #a3a3a3; }
    .Registration__input:-ms-input-placeholder {
      color: #a3a3a3; }
    .Registration__input::placeholder {
      color: #a3a3a3; }
  .Registration__form {
    text-align: center; }
    .Registration__form .dropdown {
      width: 30%;
      margin: 15px; }
  .Registration__tags p {
    margin: 5px 0 0 0; }
  .Registration__tags .dia {
    width: 40%;
    display: inline-block; }
    .Registration__tags .dia h3 {
      margin: 10px 0; }

.Tag {
  display: inline-block;
  background-color: #efefef;
  color: #a3a3a3;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  border-right: 0.5px solid #d3d3d3; }
  .Tag:last-child {
    border-right: none; }
  .Tag:hover {
    background-color: #d3d3d3; }
  .Tag--active, .Tag--active:hover {
    background-color: #2596c2;
    color: #fff; }

.print p {
  margin: 0;
  text-align: center; }

.print .barcode {
  text-align: center; }

.dates {
  width: 80%; }
  .dates .Registration__form {
    padding: 20px; }

.timer {
  position: fixed;
  top: 0;
  right: 0; }
  .timer .box {
    background: #121312;
    width: 100px;
    height: 100px;
    vertical-align: middle;
    display: inline-block; }
    .timer .box h1 {
      font-size: 50px;
      text-align: center;
      font-family: sans-serif;
      margin: 0;
      font-weight: 800;
      margin-bottom: 15px;
      color: blanchedalmond; }
    .timer .box h3 {
      text-align: center;
      color: blanchedalmond;
      margin: 0; }

.Survey {
  width: 90%;
  padding: 20px;
  background-color: white; }
  .Survey h1,
  .Survey h2,
  .Survey h3 {
    text-align: center;
    padding: 10px; }
  .Survey p {
    padding: 10px; }
  .Survey .Questions .intro p {
    margin-bottom: 0;
    font-size: 25px; }
    .Survey .Questions .intro p:nth-child(even) {
      color: #4d4d4d; }
  .Survey .Questions .intro button {
    margin: 20px auto;
    display: block; }
  .Survey .Questions .quoteSending h2 {
    padding: 41px 126px;
    font-weight: 100;
    font-size: 41px; }
  .Survey .Questions .quoteSending p {
    text-align: center;
    font-size: 30px;
    line-height: 27px; }
    .Survey .Questions .quoteSending p > small {
      color: gray; }
  .Survey .Questions .disc .question {
    width: 60%; }
  .Survey .Questions form {
    padding: 20px; }
    .Survey .Questions form .field {
      padding: 13px;
      margin: 0;
      width: 100%; }
      .Survey .Questions form .field:nth-child(even) {
        background-color: #f1f1f1; }
    .Survey .Questions form div.question {
      display: inline-block;
      width: 70%;
      font-size: 22px; }
      .Survey .Questions form div.question .text {
        display: block; }
    .Survey .Questions form div.options {
      display: inline-block; }
      .Survey .Questions form div.options label {
        padding: 4px 25px;
        font-size: 23px; }
        .Survey .Questions form div.options label:before, .Survey .Questions form div.options label:after {
          width: 22px;
          height: 22px; }
    .Survey .Questions form div.answer {
      display: block;
      padding: 6px 0 0 0; }
      .Survey .Questions form div.answer span {
        font-weight: bold; }
    .Survey .Questions form div.answers {
      display: inline-block;
      padding: 5px 0; }

@media only screen and (max-width: 1280px) {
  .Survey .Questions form.ui.form .field .question {
    width: 55%; }
  .Survey .Questions .quoteSending h2 {
    padding: 5px;
    font-size: 30px; } }

@media only screen and (max-width: 900px) {
  .Survey {
    padding: 0px; }
    .Survey .Questions form.ui.form {
      padding: 0px; }
      .Survey .Questions form.ui.form .field {
        padding: 10px 0px; }
        .Survey .Questions form.ui.form .field .question {
          padding: 15px;
          width: 100%; }
        .Survey .Questions form.ui.form .field .options {
          width: 100%;
          text-align: center; } }

.AdminTable .crecerp1c3 input {
  width: 100%; }

.AdminTable .results {
  padding: 20px 40px; }
  .AdminTable .results h1 {
    text-align: center; }
  .AdminTable .results .infocover {
    padding: 50px 30px; }
    .AdminTable .results .infocover .info {
      display: inline-block;
      padding: 20px 00 0 0;
      vertical-align: top; }
      .AdminTable .results .infocover .info p {
        margin: 5px 0; }
        .AdminTable .results .infocover .info p.userdata {
          font-size: 25px;
          padding: 10px; }
          .AdminTable .results .infocover .info p.userdata:first-child {
            padding-top: 0px; }
  .AdminTable .results .parents {
    padding: 0 0 0 20px; }
  .AdminTable .results #tutorForm {
    margin: 20px 0 0 0;
    display: inline-block;
    background-color: #afafaf;
    width: 45%;
    border-radius: 20px;
    padding: 20px;
    text-align: center; }
    .AdminTable .results #tutorForm textarea {
      display: block;
      width: 80%; }
    .AdminTable .results #tutorForm .ui.checkbox {
      margin: 30px 0 20px 0; }
  .AdminTable .results .DISC {
    padding-left: 20px; }
    .AdminTable .results .DISC h2 {
      margin: 30px 0 0 -20px; }
    .AdminTable .results .DISC h3 {
      margin: 10px 0;
      font-size: 63px; }
    .AdminTable .results .DISC strong {
      display: inline-block;
      margin: 25px 0; }
    .AdminTable .results .DISC .first {
      margin: 40px 0 10px 0; }
    .AdminTable .results .DISC .second {
      margin: 10px 0 20px 0; }
  .AdminTable .results .spiritual {
    padding-left: 20px;
    border-top: 1px solid gray;
    margin-top: 30px; }
    .AdminTable .results .spiritual h2 {
      margin: 20px 0 0 -20px; }
    .AdminTable .results .spiritual p span {
      font-style: italic; }
    .AdminTable .results .spiritual h3 b {
      font-weight: 300; }
  .AdminTable .results .connecting {
    border-top: 1px solid gray;
    padding-left: 20px;
    margin-top: 30px; }
    .AdminTable .results .connecting h2 {
      margin: 20px 0 0 -20px; }

@media only screen and (max-width: 650px) {
  .AdminTable {
    padding: 10px 15px; }
    .AdminTable .results {
      padding: 20px 0px; }
      .AdminTable .results .infocover {
        padding: 0px; }
      .AdminTable .results .DISC h2 {
        margin-left: 0; } }

