.CheckIn {
  width: 70%;
  background-color: $color__white;
  border-radius: 22px;
  height: auto;
  max-width: 80vw;
  padding: 1.1rem;
  .info {
    margin: 20px 0;
    span {
      margin: 0 10px;
    }
  }
  @media only screen and (max-width: 700px) {
    width: 95%;
  }
}
.GuestIdForm {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
  form {
    max-width: 300px;
  }
  .Registration__input {
    padding: 0;
  }
}
