.Survey {
  width: 90%;
  padding: 20px;
  background-color: white;
  h1,
  h2,
  h3 {
    text-align: center;
    padding: 10px;
  }
  p {
    padding: 10px;
  }
  .Questions {
    .intro {
      p {
        margin-bottom: 0;
        font-size: 25px;
        &:nth-child(even) {
          color: rgb(77, 77, 77);
        }
      }
      button {
        margin: 20px auto;
        display: block;
      }
    }
    .quoteSending {
      h2 {
        padding: 41px 126px;
        font-weight: 100;
        font-size: 41px;
      }
      p {
        text-align: center;
        font-size: 30px;
        line-height: 27px;
        > small {
          color: gray;
        }
      }
    }
    .disc {
      .question {
        width: 60%;
      }
    }
    form {
      padding: 20px;
      .field {
        padding: 13px;
        margin: 0;
        width: 100%;
        &:nth-child(even) {
          background-color: rgb(241, 241, 241);
        }
      }
      div {
        &.question {
          display: inline-block;
         .text {
            display: block;
          }
          width: 70%;
          font-size: 22px;
        }
        &.options {
          display: inline-block;
          label {
            padding: 4px 25px;
            font-size: 23px;
            &:before,
            &:after {
              width: 22px;
              height: 22px;
            }
          }
        }
        &.answer {
          display: block;
          padding: 6px 0 0 0;
          span {
            font-weight: bold;
          }
        }
        &.answers {
          display: inline-block;
          padding: 5px 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .Survey {
    .Questions {
      form {
        &.ui.form {
          .field {
            .question {
              width: 55%;
            }
          }
        }
      }
      .quoteSending {
        h2 {
          padding: 5px;
          font-size: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .Survey {
    padding: 0px;
    .Questions {
      form {
        &.ui.form {
          padding: 0px;
          .field {
            padding: 10px 0px;
            .question {
              padding: 15px;
              width: 100%;
            }
            .options {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
@import './Answers.scss';
