h1,
h2 {
  margin: 0;
}
p {
  margin: 0;
  small {
    font-size: 0.75em;
  }
}
.printable {
  padding: 30px;
}

@import './settings.scss';
@import './Layout.scss';
@import './components/Home.scss';
@import './components/AdminTable.scss';
@import './components/Button.scss';
@import './components/CheckIn.scss';
@import './components/Header.scss';
@import './components/Registration.scss';
@import './components/Tag.scss';
@import './components/Barcode.scss';
@import './components/Dates.scss';
@import './components/Counter.scss';
@import '../../containers/surveys/Questions.scss';
