.timer {
    position: fixed;
    top: 0;
    right: 0;
    .box{
        background: rgb(18, 19, 18);
        width: 100px;
        height: 100px;
        vertical-align: middle;
        display: inline-block;
        h1{
            font-size: 50px;
            text-align: center;
            font-family: sans-serif;
            margin: 0;        
            font-weight: 800;
            margin-bottom: 15px;
            color: blanchedalmond;
        }
        h3{
            text-align: center;
            color: blanchedalmond;
            margin: 0;        
        }
    }
}